import React, { useEffect, useState } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Navigation from "./Navigation";
import About from "./About";
import Resume from "./Resume";
import Portfolio from "./Portfolio";
import { toast } from "react-toastify";
import Certifications from "./Certification";

function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        toast.error("The page you requested was not found.");
        navigate(-1);
    }, [navigate]);

    return null;
}

const AnimatedRoutes = ({ setIsLoading }) => {
    const location = useLocation();
    const [certifications, setCertifications] = useState([]);
    const [portfoliosData, setPortfoliosData] = useState([]);
    const [listCategory, setListCategory] = useState([]);

    useEffect(() => {
        const fetchInformation = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(
                    "https://apicloudpix.natanaelgeraldo.com/api/certifications/get-all",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    }
                );

                if (!response.ok) {
                    setIsLoading(false);
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const certifications = await response.json();
                setCertifications(certifications);

                const responsePortfolios = await fetch(
                    "https://apicloudpix.natanaelgeraldo.com/api/portfolios/get-all",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    }
                );

                if (!responsePortfolios.ok) {
                    setIsLoading(false);
                    throw new Error(
                        `HTTP error! Status: ${responsePortfolios.status}`
                    );
                }

                const portfolios = await responsePortfolios.json();

                setPortfoliosData(portfolios);
                const categories = [
                    "All",
                    ...new Set(portfolios.map((item) => item.type).sort()),
                ];
                setListCategory(categories);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching:", error);
            }
        };

        fetchInformation();
    }, [setIsLoading]);

    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={500}>
                <div className="route-wrapper">
                    <Routes location={location}>
                        <Route path="/" element={<About />} />
                        <Route path="/resume" element={<Resume />} />
                        <Route
                            path="/certification"
                            element={
                                <Certifications
                                    certifications={certifications}
                                />
                            }
                        />
                        <Route
                            path="/portfolio"
                            element={
                                <Portfolio
                                    portfolios={portfoliosData}
                                    categories={listCategory}
                                />
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </CSSTransition>
        </TransitionGroup>
    );
};

const Content = ({ setIsLoading }) => {
    return (
        <BrowserRouter>
            <div className="transition-all duration-500 min-w-[270px] max-w-[900px] w-full lg:ml-8 mt-8 lg:mt-0 bg-opacity-40 bg-gray-600 border border-gray-400 rounded-3xl p-7 overflow-hidden lg:relative py-5 text-left text-white">
                <Navigation />
                <AnimatedRoutes setIsLoading={setIsLoading} />
            </div>
        </BrowserRouter>
    );
};

export default Content;
