import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SideProfile from "./SideProfile/SideProfile";
import Content from "./MainContent/Content";
import { useState } from "react";
import LoadingSpinner from "./Common/LoadingSpinner";
import Globe from "./Common/Globe";

function App() {
    const [isLoading, setIsLoading] = useState(true);
    return (
        <div className="App bg-gradient-light dark:bg-gradient-dark h-[100vh]">
            <Globe />
            {isLoading && <LoadingSpinner />}
            <div className="container justify-center px-4 mx-auto py-12 lg:flex">
                <SideProfile />
                <Content setIsLoading={setIsLoading} />
            </div>
            <ToastContainer />
        </div>
    );
}

export default App;
