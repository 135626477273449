import React from "react";

const PortfolioContainer = ({ PortfolioData, openDetail }) => {
    const { id, title, description, type } = PortfolioData;

    return (
        <div
            onClick={openDetail(id)}
            className="group relative overflow-hidden bg-white bg-opacity-5 rounded-2xl backdrop-blur-sm 
                     border border-gray-500 hover:border-blue-400 p-6 cursor-pointer transition-all duration-300"
        >
            <div className="mb-4">
                <span className="inline-block bg-blue-500 bg-opacity-20 text-blue-300 text-sm px-4 py-1.5 rounded-full">
                    {type}
                </span>
            </div>

            <h3 className="text-2xl font-bold mb-3 group-hover:text-blue-400 transition-colors duration-300">
                {title}
            </h3>

            <p className="text-gray-300 text-sm leading-relaxed line-clamp-3 mb-4">
                {description}
            </p>

            <div className="absolute inset-0 bg-gradient-to-t from-blue-500/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        </div>
    );
};
export default PortfolioContainer;
