import React, { useState } from "react";
import { FaExternalLinkAlt, FaTimes } from "react-icons/fa";
import { BsFullscreen } from "react-icons/bs";
import SectionTitle from "../Common/SectionTitle";
import getImageLink from "../../functions/getImageLink";

const ImageModal = ({ isOpen, onClose, image, name }) => {
    if (!isOpen) return null;

    return (
        <div
            data-te-modal-init
            className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-gray-900 bg-opacity-90 m-auto flex mt-0"
        >
            <div className="max-w-lg min-h-[100px] max-h-screen m-auto rounded-lg p-5 relative bg-Third-Color border-gray-500 border overflow-y-auto overflow-x-hidden">
                <button
                    className="top-5 right-5 absolute bg-gray-800 bg-opacity-60 py-3 px-3 rounded-md hover:bg-opacity-40 transition-all duration-200"
                    onClick={onClose}
                >
                    <FaTimes />
                </button>
                <img
                    src={getImageLink(image)}
                    alt={name}
                    className="w-full h-auto rounded-lg"
                />
            </div>
        </div>
    );
};

const CertificationCard = ({ certification }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const formatDate = (dateString, defaultReturn) => {
        if (!dateString) return defaultReturn;
        return new Date(dateString).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
        });
    };

    return (
        <>
            <div className="bg-[#1a2942]/50 rounded-lg p-6 backdrop-blur-sm hover:bg-[#1a2942]/70 border border-gray-500 transition-all duration-300">
                <div className="flex flex-col gap-6">
                    {/* Certificate Image Section */}
                    <div className="relative group">
                        <img
                            src={getImageLink(certification.imageLink)}
                            alt={`${certification.name} Certificate`}
                            className="w-full h-48 object-cover rounded-lg cursor-pointer"
                            onClick={() => setIsModalOpen(true)}
                        />
                        <div
                            className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <BsFullscreen className="w-8 h-8 text-white" />
                        </div>
                    </div>

                    {/* Certificate Details Section */}
                    <div className="flex items-start gap-4">
                        {certification.logo ? (
                            <img
                                src={certification.logo}
                                alt={`${certification.organization} logo`}
                                className="w-12 h-12 rounded object-contain bg-[#1a2942]/50 p-2 flex-shrink-0"
                            />
                        ) : (
                            ""
                        )}
                        <div className="flex-1">
                            <div className="flex items-start justify-between gap-4">
                                <div>
                                    <h3 className="text-lg font-medium text-white">
                                        {certification.name}
                                    </h3>
                                    <p className="text-gray-400">
                                        {certification.organization}
                                    </p>
                                </div>
                                {certification.link ? (
                                    <a
                                        href={certification.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-400 hover:text-blue-400 transition-colors"
                                    >
                                        <FaExternalLinkAlt className="h-4 w-4" />
                                    </a>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="mt-2 flex items-center gap-2 text-sm">
                                <span className="px-3 py-1 rounded-full bg-[#1a2942]/80 text-gray-300 border-gray-300 border">
                                    {certification.category}
                                </span>
                            </div>

                            <div className="mt-3 flex items-center gap-3 text-sm text-gray-400">
                                <span>
                                    Awarded:{" "}
                                    {formatDate(certification.dateAwarded, "-")}
                                </span>
                                <span className="h-1 w-1 rounded-full bg-gray-600" />
                                <span>
                                    Expires:{" "}
                                    {formatDate(
                                        certification.expiration,
                                        "No Expiration"
                                    )}
                                </span>
                            </div>

                            <p className="mt-3 text-gray-300 text-sm">
                                {certification.description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <ImageModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                image={certification.imageLink}
                name={certification.name}
            />
        </>
    );
};

const Certifications = ({ certifications }) => {
    return (
        <div className="max-w-4xl mx-auto">
            <SectionTitle SectionName="Certification" />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                {certifications.map((certification) => (
                    <CertificationCard
                        key={certification.id}
                        certification={certification}
                    />
                ))}
            </div>
        </div>
    );
};

export default Certifications;
