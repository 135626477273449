import React from "react";

const LoadingSpinner = () => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900/90 backdrop-blur-sm transition-opacity">
            <div className="bg-gray-800 p-8 rounded-xl shadow-xl flex flex-col items-center gap-6 animate-fade-in border border-gray-700">
                <div className="relative">
                    <svg
                        className="w-full h-auto max-w-md"
                        viewBox="0 0 1322 734"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <style>
                            {`
                @keyframes drawPath {
                  to {
                    stroke-dashoffset: 0;
                  }
                }

                @keyframes fillIn {
                  from {
                    fill: transparent;
                  }
                  to {
                    fill: #E5E7EB;
                  }
                }

                @keyframes pulse {
                  0%, 100% {
                    opacity: 1;
                    transform: scale(1);
                  }
                  50% {
                    opacity: 0.8;
                    transform: scale(0.98);
                  }
                }

                @keyframes shimmer {
                  0% {
                    background-position: -200% center;
                  }
                  100% {
                    background-position: 200% center;
                  }
                }

                .logo-path {
                  stroke: #E5E7EB;
                  stroke-width: 2;
                  fill: none;
                  stroke-dasharray: 3000;
                  stroke-dashoffset: 3000;
                  animation: 
                    drawPath 3s ease forwards,
                    fillIn 2s ease 2s forwards,
                    pulse 2s ease-in-out infinite 4s;
                }

                .shimmer {
                  background: linear-gradient(
                    90deg,
                    rgba(229,231,235,0) 0%,
                    rgba(229,231,235,0.1) 50%,
                    rgba(229,231,235,0) 100%
                  );
                  background-size: 200% 100%;
                  animation: shimmer 2s infinite;
                }
              `}
                        </style>
                        <path
                            className="logo-path"
                            d="M34.3047 717C11.5182 717 0.125 704.793 0.125 680.379V53.4258C0.125 29.0117 11.5182 16.8047 34.3047 16.8047H106.57C134.565 16.8047 155.724 28.1979 170.047 50.9844L419.07 450.887H422.488C420.861 431.355 420.047 411.824 420.047 392.293V53.4258C420.047 29.0117 431.44 16.8047 454.227 16.8047H528.445C551.232 16.8047 562.625 29.0117 562.625 53.4258V680.379C562.625 704.793 551.232 717 528.445 717H461.062C433.068 717 411.909 705.607 397.586 682.82L143.68 275.594H140.262C141.889 295.125 142.703 314.656 142.703 334.188V680.379C142.703 704.793 131.31 717 108.523 717H34.3047Z"
                        />
                        <path
                            className="logo-path"
                            d="M682.742 366.902C682.742 254.598 712.039 165.405 770.633 99.3242C829.227 33.2435 912.234 0.203125 1019.66 0.203125C1084.43 0.203125 1135.22 8.50391 1172 25.1055C1209.11 41.707 1241.66 67.4232 1269.66 102.254C1286.58 123.413 1286.1 139.852 1268.19 151.57L1212.53 187.703C1196.25 198.445 1178.84 193.725 1160.28 173.543C1144.98 156.941 1125.61 143.107 1102.18 132.039C1078.74 120.971 1052.86 115.438 1024.54 115.438C956.18 115.438 907.514 137.085 878.543 180.379C849.572 223.348 835.086 285.522 835.086 366.902C835.086 448.283 849.572 510.62 878.543 553.914C907.514 596.883 956.18 618.367 1024.54 618.367C1057.74 618.367 1086.39 615.112 1110.48 608.602C1134.57 601.766 1157.35 589.559 1178.84 571.98V484.578H1072.88C1048.46 484.578 1036.26 473.999 1036.26 452.84V395.223C1036.26 374.064 1048.46 363.484 1072.88 363.484H1284.79C1309.21 363.484 1321.41 374.064 1321.41 395.223V643.27C1275.84 676.798 1229.78 700.236 1183.23 713.582C1137.01 726.928 1082.48 733.602 1019.66 733.602C913.862 733.602 831.18 700.561 771.609 634.48C712.365 568.4 682.742 479.207 682.742 366.902Z"
                        />
                    </svg>
                    <div className="absolute inset-0 shimmer pointer-events-none" />
                </div>
                <div className="flex flex-col items-center gap-2">
                    <div className="h-1 w-32 bg-gray-700 rounded-full overflow-hidden">
                        <div className="h-full w-1/2 bg-gray-300 rounded-full animate-bounce" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingSpinner;
