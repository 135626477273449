import React, { useState } from "react";
import { motion } from "framer-motion";
import {
    RiArrowLeftLine,
    RiGithubFill,
    RiExternalLinkLine,
} from "react-icons/ri";
import SectionTitle from "../../Common/SectionTitle";
import getImageLink from "../../../functions/getImageLink";

const PortfolioDetail = ({ content, closeDetail }) => {
    const {
        title,
        description,
        type,
        github,
        previewLink,
        technologies,
        keyFeatures,
        images,
    } = content;
    const [selectedImage, setSelectedImage] = useState(
        images[0]?.filename || null
    );

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.4,
                staggerChildren: 0.1,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.4 },
        },
    };

    return (
        <motion.div
            className="w-full bg-opacity-10 text-white"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <motion.div className="w-full p-4 lg:p-6" variants={itemVariants}>
                <motion.button
                    onClick={closeDetail}
                    className="flex items-center p-2 bg-white bg-opacity-10 rounded-xl border border-white hover:bg-opacity-20 transition-all"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <RiArrowLeftLine className="mt-[1px]" />
                    <span className="ml-2">Back</span>
                </motion.button>

                <motion.div className="mt-6" variants={itemVariants}>
                    <SectionTitle SectionName={title} />
                    <p className="text-sm text-gray-400 mt-2">{type}</p>
                </motion.div>
            </motion.div>

            <div className="w-full p-4 pt-0 lg:p-6">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    <div className="lg:col-span-2 space-y-6">
                        {images && images.length > 0 && (
                            <motion.div
                                className="mb-6"
                                variants={itemVariants}
                            >
                                <motion.div
                                    className="w-full max-h-[200px] md:max-h-[300px] lg:max-h-[400px] rounded-xl overflow-hidden bg-white bg-opacity-5"
                                    layoutId="mainImage"
                                >
                                    <motion.img
                                        src={getImageLink(selectedImage)}
                                        alt="Project preview"
                                        className="w-full h-full object-cover object-center"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.3 }}
                                    />
                                </motion.div>

                                {images.length > 1 && (
                                    <motion.div
                                        className="flex gap-2 mt-4 overflow-x-auto pb-2"
                                        variants={itemVariants}
                                    >
                                        {images.map((image) => (
                                            <motion.button
                                                key={image.id}
                                                onClick={() =>
                                                    setSelectedImage(
                                                        image.filename
                                                    )
                                                }
                                                className={`flex-shrink-0 w-24 h-24 rounded-lg overflow-hidden 
                                                    ${
                                                        selectedImage ===
                                                        image.filename
                                                            ? "ring-2 ring-blue-500"
                                                            : "opacity-70"
                                                    }`}
                                                whileHover={{ scale: 1.05 }}
                                                whileTap={{ scale: 0.95 }}
                                            >
                                                <img
                                                    src={getImageLink(
                                                        image.filename
                                                    )}
                                                    alt="Project thumbnail"
                                                    className="w-full h-full object-cover"
                                                />
                                            </motion.button>
                                        ))}
                                    </motion.div>
                                )}
                            </motion.div>
                        )}

                        <motion.div
                            className="bg-white bg-opacity-5 rounded-xl p-6"
                            variants={itemVariants}
                        >
                            <h3 className="text-xl font-semibold mb-4">
                                Description
                            </h3>
                            <p className="text-gray-300 leading-relaxed">
                                {description}
                            </p>
                        </motion.div>

                        {keyFeatures && keyFeatures.length > 0 && (
                            <motion.div
                                className="bg-white bg-opacity-5 rounded-xl p-6"
                                variants={itemVariants}
                            >
                                <h3 className="text-xl font-semibold mb-4">
                                    Key Features
                                </h3>
                                <ul className="list-disc list-inside space-y-2">
                                    {keyFeatures.map((feature, index) => (
                                        <motion.li
                                            key={index}
                                            className="text-gray-300"
                                            variants={itemVariants}
                                        >
                                            {feature.description}
                                        </motion.li>
                                    ))}
                                </ul>
                            </motion.div>
                        )}
                    </div>

                    <div className="space-y-6">
                        <motion.div
                            className="bg-white bg-opacity-5 rounded-xl p-6"
                            variants={itemVariants}
                        >
                            <h3 className="text-xl font-semibold mb-4">
                                Technologies Used
                            </h3>
                            <div className="flex flex-wrap gap-2">
                                {technologies.map((tech) => (
                                    <motion.span
                                        key={tech.id}
                                        className="px-3 py-1 bg-white bg-opacity-10 rounded-full text-sm"
                                        whileHover={{ scale: 1.05 }}
                                        variants={itemVariants}
                                    >
                                        {tech.name}
                                    </motion.span>
                                ))}
                            </div>
                        </motion.div>

                        {github && previewLink && (
                            <motion.div
                                className="bg-white bg-opacity-5 rounded-xl p-6"
                                variants={itemVariants}
                            >
                                <h3 className="text-xl font-semibold mb-4">
                                    Project Links
                                </h3>
                                <div className="space-y-3">
                                    {github && (
                                        <motion.a
                                            href={github}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
                                            whileHover={{ scale: 1.05, x: 10 }}
                                        >
                                            <RiGithubFill size={20} />
                                            <span>View Source Code</span>
                                        </motion.a>
                                    )}
                                    {previewLink && (
                                        <motion.a
                                            href={previewLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
                                            whileHover={{ scale: 1.05, x: 10 }}
                                        >
                                            <RiExternalLinkLine size={20} />
                                            <span>Live Preview</span>
                                        </motion.a>
                                    )}
                                </div>
                            </motion.div>
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default PortfolioDetail;
