import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import SectionTitle from "../Common/SectionTitle";
import PortfolioContainer from "./ContainerComponent/PortfolioContainer";
import PortfolioDetail from "./ContainerComponent/PortfolioDetail";

const Portfolio = ({ portfolios, categories }) => {
    const [activeCategory, setActiveCategory] = useState("All");
    const [filteredPortfolios, setFilteredPortfolios] = useState(null);
    const [detailPortfolio, setDetailPortfolio] = useState(false);
    const [detailContent, setDetailContent] = useState({});

    useEffect(() => {
        setFilteredPortfolios(
            activeCategory.toLowerCase() === "all"
                ? portfolios
                : portfolios.filter((x) => x.type === activeCategory)
        );
    }, [portfolios, activeCategory]);

    const changeCategory = (event) => {
        setActiveCategory(event.target.value);
    };

    const openDetail = (id) => () => {
        setDetailPortfolio(true);
        const portfolioData = portfolios.find((x) => x.id === id);
        setDetailContent(portfolioData);
    };

    const closeDetail = () => {
        setDetailPortfolio(false);
        setDetailContent(null);
    };

    if (detailPortfolio)
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <PortfolioDetail
                    content={detailContent}
                    closeDetail={closeDetail}
                />
            </motion.div>
        );

    return (
        <div>
            <SectionTitle SectionName="Portfolio" />

            <div className="mt-8 max-w-md">
                <label
                    htmlFor="categorySelect"
                    className="block mb-3 text-lg font-medium text-gray-200"
                >
                    Select Category
                </label>
                <div className="relative">
                    <select
                        id="categorySelect"
                        onChange={changeCategory}
                        value={activeCategory}
                        className="block w-full px-4 py-3 rounded-xl bg-gray-800 border border-gray-600 
            text-gray-200 hover:border-gray-400 transition-colors duration-200 appearance-none 
            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
            cursor-pointer"
                    >
                        {categories?.map((category) => (
                            <option
                                value={category}
                                key={category}
                                className="bg-gray-800 text-gray-200"
                            >
                                {category}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-400">
                        <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 mt-8">
                {filteredPortfolios?.map((portfolio) => (
                    <PortfolioContainer
                        key={portfolio.id}
                        PortfolioData={portfolio}
                        openDetail={openDetail}
                    />
                ))}
            </div>
        </div>
    );
};

export default Portfolio;
