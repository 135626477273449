import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
    RiAccountCircleLine,
    RiMedalLine,
    RiFolder3Line,
    RiAccountCircleFill,
    RiMedalFill,
    RiFolder3Fill,
    RiAwardLine,
    RiAwardFill,
} from "react-icons/ri";
const Navigation = () => {
    const Location = useLocation();
    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <div className="flex justify-center">
            <div className="bg-Third-Color lg:bg-white bg-opacity-70 lg:bg-opacity-10 border border-white fixed lg:absolute bottom-5 lg:bottom-auto lg:top-0 lg:right-0 py-3 rounded-full lg:rounded-tl-none lg:rounded-br-none lg:rounded-tr-none lg:rounded-bl-2xl min-w-[270px] w-fit lg:left-auto flex justify-center backdrop-blur-sm z-40 ">
                <p
                    className={`${
                        Location.pathname === "/"
                            ? "font-semibold underline"
                            : "hover:text-gray-300 transition-all duration-100"
                    } inline-block mx-5 drop-shadow-m`}
                >
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={handleLinkClick}
                    >
                        {Location.pathname === "/" ? (
                            <RiAccountCircleFill size={25} />
                        ) : (
                            <RiAccountCircleLine size={25} />
                        )}
                        <span className="hidden lg:block ml-1">About</span>
                    </Link>
                </p>
                <p
                    className={`${
                        Location.pathname === "/resume"
                            ? "font-semibold underline"
                            : "hover:text-gray-300 transition-all duration-100"
                    } inline-block mx-5 drop-shadow-md`}
                >
                    <Link
                        to="/resume"
                        className="flex items-center"
                        onClick={handleLinkClick}
                    >
                        {Location.pathname === "/resume" ? (
                            <RiMedalFill size={25} />
                        ) : (
                            <RiMedalLine size={25} />
                        )}
                        <span className="hidden lg:block ml-1">Resume</span>
                    </Link>
                </p>
                <p
                    className={`${
                        Location.pathname === "/certification"
                            ? "font-semibold underline"
                            : "hover:text-gray-300 transition-all duration-100"
                    } inline-block mx-5 drop-shadow-md`}
                >
                    <Link
                        to="/certification"
                        className="flex items-center"
                        onClick={handleLinkClick}
                    >
                        {Location.pathname === "/certification" ? (
                            <RiAwardFill size={25} />
                        ) : (
                            <RiAwardLine size={25} />
                        )}
                        <span className="hidden lg:block ml-1">
                            Certification
                        </span>
                    </Link>
                </p>
                <p
                    className={`${
                        Location.pathname === "/portfolio"
                            ? "font-semibold underline"
                            : "hover:text-gray-300 transition-all duration-100"
                    } inline-block mx-5 drop-shadow-md`}
                >
                    <Link
                        to="/portfolio"
                        className="flex items-center"
                        onClick={handleLinkClick}
                    >
                        {Location.pathname === "/portfolio" ? (
                            <RiFolder3Fill size={25} />
                        ) : (
                            <RiFolder3Line size={25} />
                        )}
                        <span className="hidden lg:block ml-1">Portfolio</span>
                    </Link>
                </p>
                {/*<p
                className={`${
                    Location.pathname === "/Contact"
                        ? "font-semibold underline"
                        : ""
                } inline-block mr-5 drop-shadow-md`}
            >
                <Link to="/Contact">Contact</Link>
            </p> */}
            </div>
        </div>
    );
};
export default Navigation;
